<template>
  <div class="container">
    <van-nav-bar
        left-text="路线指引"
        left-arrow fixed placeholder
        z-index="99"
        @click-left="$router.push({name: 'Guide'})"
    />
    <div class="content">
      <h2>新明辉河东办公区路线</h2>
      <div class="info">
        <div class="info_item one">
          <div class="title">
            <img src="../../../static/img/1.png" alt="">
            <p>面试地址</p>
          </div>
          <p class="txt">本次面试地址位于山东省临沂市沂河新区海关路名都国际北50米中关村临沂软件产业基地C区3楼C311、C312、C314、C316</p>
        </div>
        <div class="info_item two">
          <div class="title">
            <img src="../../../static/img/2.png" alt="">
            <p>乘车路线</p>
          </div>
          <div class="item first">
            <p class="text"><span>①</span>公交路线：K7路和K39路，站点中关村临沂软件园产业基地</p>
            <p class="text">途经公交：K7、K39路</p>
          </div>
<!--          <div class="item last">-->
<!--            <p class="text"><span>②</span>公交路线：目的地蜜欢购物广场东门楼下</p>-->
<!--            <p class="text">途径公交：K3、K18路</p>-->
<!--          </div>-->
          <div class="tip">
            <div class="tip_title">
              <img src="../../../static/img/3.png" alt="">
              <p><span>温馨小提示：</span>微信搜索小程序—乘车码，可以实时把控公交到站时间，避免寒风中等待哦~~</p>
            </div>
            <div class="ByBus">
              <img src="../../../static/img/4.png" alt="">
            </div>
          </div>
        </div>
<!--        <div class="info_item three">-->
<!--          <div class="title">-->
<!--            <img src="../../../static/img/5.png" alt="">-->
<!--            <p>视频导航到达目的地</p>-->
<!--          </div>-->
<!--          <p class="txt">到达蜜欢购物广场后，根据你当前的位置，选择相应的视频观看，帮你导航到达目的地！</p>-->
<!--          <div class="video">-->
<!--            <p class="video_title">由清泉庄路公交站到目的地</p>-->
<!--            <video controls poster="../../../static/img/6.png" src="../../../static/video/jinquangongjiao.mp4"></video>-->
<!--          </div>-->
<!--          <div class="video">-->
<!--            <p class="video_title">由房源龙河湾站到达目的地</p>-->
<!--            <video controls poster="../../../static/img/7.png" src="../../../static/video/jinquandong.mp4"></video>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gongyeyuanqu"
}
</script>

<style scoped lang="scss">
p{
  margin: 0;
}
.content{
  width: 335px;
  margin: 0 auto;
  h2{
    font-family: PingFang-SC-Medium;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #666666;
    text-align: center;
    margin-top: 20px;
  }
  .workPlace{
    font-family: PingFang-SC-Medium;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #999999;
    text-align: center;
    margin-top: 7px;
  }
  .info{
    padding-top: 15px;
    padding-bottom: 30px;
    .info_item{
      .title{
        width: 130px;
        height: 37px;
        background-color: #02dbca;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.11);
        border-radius: 19px;
        display: flex;
        align-items: center;
        margin-top: 5px;
        img{
          width: 30px;
          height: 30px;
          flex-shrink: 0;
          margin-left: 5px;
        }
        p{
          font-family: PingFang-SC-Medium;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
          margin-left: 7px;
        }
      }
      .txt{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #666666;
        line-height: 22px;
        padding: 15px 0;
      }
    }
    .one{

    }
    .two{
      .title{
        background-color: #3399ff;
      }
      .item{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        line-height: 25px;
        color: #666666;
        padding: 18px 0 14px;
        border-bottom: 1px dashed #d4d4d4;
        .text{
          display: flex;
          span{
            display: block;
            margin-right: 2px;
            margin-top: -1px;
          }
        }
        &:last-child{
          border:none;
        }
        .text:last-child{
          text-indent: 1em;
        }
      }
      .tip{
        width: 100%;
        background-color: #ffffff;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 20px 25px;
        border-radius: 7px;
        margin-top: 12px;
        margin-bottom: 25px;
        .tip_title{
          display: flex;
          img{
            width: 22px;
            height: 22px;
            margin-right: 10px;
            margin-top: 1px;
          }
          p{
            font-family: PingFang-SC-Medium;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            line-height: 22px;
            color: #ff3333;
            span{
              font-family: PingFang-SC-Bold;
            }
          }
        }
        .ByBus{
          margin-top: 25px;
          img{
            width: 217px;
            margin: 0 auto;
            display: block;
          }
        }
      }
      .last{
        border: none;
        padding-top: 8px;
      }
    }
    .three{
      .title{
        width: 204px;
        background-color: #66cc99;
      }
      .txt{
        margin-top: 3px;
        margin-bottom: 10px;
      }
      .video{
        background-color: #ffffff;
        box-shadow: 0px 0px 7px 0px
        rgba(0, 0, 0, 0.1);
        width: 100%;
        border-radius: 7px;
        box-sizing: border-box;
        padding: 20px 15px;
        margin-bottom: 20px;
        .video_title{
          font-family: PingFang-SC-Medium;
          font-size: 17px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #666666;
          text-align: center;
        }
        video{
          width: 100%;
          //height: 224px;
          margin-top: 10px;
          object-fit: fill;
        }
      }
    }
  }
}
::v-deep .van-nav-bar__arrow{
  font-size: 19px;
  color: #666666;
}
::v-deep .van-nav-bar__text{
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>